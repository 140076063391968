module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#f0e6f6',

  primary: '#a8b2d1',
  primaryLight: '#ccd6f6',
  primaryDark: '#8892b0',

  secondary: '#64ffda',
  secondaryLight: '#64ffda',
  secondaryDark: '#64ffda',

  dark: '#000007',
  darkestNavy: '#01050b',
  darkNavy: '#020c1b',
  navy: '#0a192f',
  lightNavy: '#172a45',
  darkGrey: '#333f58',
  mediumGrey: '#2d3952',
  grey: '#4c5772',
  lightGrey: '#606a86',
  slate: '#8892b0',
  lightSlate: '#a8b2d1',
  lightestSlate: '#ccd6f6',
  offWhite: '#dce7ff',
  white: '#e6f1ff',
  pink: '#FF647F',
  yellow: '#FFC464',
  orange: '#FF9E64',
  green: '#64ffda',
  blue: '#71AFFF',
  darkBlue: '#1D7FFC',
  highlight: 'rgba(41, 61, 90, 0.99)',
  transGreen: 'rgba(100, 255, 218, 0.07)',
  transNavy: 'rgba(10, 25, 47, 0.7)',
  shadowNavy: 'rgba(2, 12, 27, 0.7)',
};
